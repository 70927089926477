
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { User } from "@/store/user/types";
import { USER_GETTERS } from "@/store/user/getters";

import { firestore } from '@/firebase'
import { Timestamp, addDoc, collection, doc, getDocs, query, where } from "firebase/firestore";
import { Mail } from "@/types";
import * as emails from '@/bin/emails'

@Component
export default class NewUserDialog extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) defaultGroup!: string;

  languages = [
    {
      text: 'Nederlands',
      value: 'nl-be'
    },
    {
      text: 'Frans',
      value: 'fr'
    }
  ]

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  groups = [
    { text: 'Admin', value: 'ADMIN' },
    { text: "Coaches", value: "COACH" },
    { text: "Deelnemers", value: "PARTICIPANT" },
    { text: "Bedrijfscontacten", value: "COMPANY_CONTACT" },
    { text: "Interim managers", value: "INTERIM_MANAGER" },
  ];


  inputs = {
    lastname: "",
    firstname: "",
    type: "COACH",
    email: "",
    sendInvite: false,
    phone: '',
    language: 'nl-be'
  };

  get formattedPhone() {
    if (this.inputs.phone.substr(0, 4) == "0032") {
      return this.inputs.phone.replace("0032", "+32");
    } else if (this.inputs.phone.substr(0, 2) == "04") {
      return "+32" + this.inputs.phone.substr(1);
    } else {
      return this.inputs.phone;
    }
  }

  loading = {
    submit: false,
  };

  @Watch('defaultGroup')
  onOpen() {
    if (this.defaultGroup) this.inputs.type = this.defaultGroup
  }

  async submit() {
    try {
      this.loading.submit = true;

      const currentNewUsersWithEmail = await getDocs(query(collection(firestore, 'new-users'), where('email', '==', this.inputs.email)))
      const currentUsersWithEmail = await getDocs(query(collection(firestore, 'users'), where('email', '==', this.inputs.email)))
      if (!currentUsersWithEmail.empty || !currentNewUsersWithEmail.empty) {

        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: "error",
          text: "Er is al een gebruiker met dit emailadres!",
        });
        this.loading.submit = false;
        return;
      }

      await addDoc(collection(firestore, 'new-users'), {
        ...this.inputs,
        email: this.inputs.email.toLowerCase(),
        phone: this.formattedPhone
      })

      this.show = false;
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Gebruiker uitgenodigd",
      });
      this.$emit("refresh");
      this.inputs = {
        lastname: "",
        firstname: "",
        type: "COACH",
        email: "",
        sendInvite: false,
        phone: '',
        language: 'nl-be'
      };
    } catch (error) {
      let text = error;
      

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text,
      });
    } finally {
      this.loading.submit = false;
    }
  }
}
